<template>
  <div class="wrapper">
    <div>
      <a
        data-cy="download-button"
        class="brand-common-btn"
        :style="buttonStyle"
        @click="getDownloadUrl"
      >
        <span :style="buttonBeforeStyle" />
        下载
        <span :style="buttonAfterStyle" />
      </a>
      <div class="tip_text">
        <a
          data-cy="solution-link"
          class="solution"
          href="/solution.html"
          @click="clickHelpDoc"
          target="_blank"
        >
          成人APP会被误报病毒 忽略即可(查看帮助)
        </a>
      </div>
    </div>
    <a
      :href="android_download_url"
      ref="android_download_link"
      style="display: none"
    />
  </div>
</template>

<script>
import {
  ANDROID,
  ANDROID_WARN,
  COPY_SUCCESS,
  CLICK_DOWNLOAD,
  GET_DOWNLOAD_URL,
  OPEN_B,
} from '@/../config'
import { CLICK_SOLUTION } from '@/../config/event_name'
import {
  clickCopy,
  getQueryValues,
  uploadDownloadEvent,
  triggerDownloadApk,
} from '@/../utils'
import { getAndroidDownloadUrl } from '@/service'
export default {
  props: {
    showWarn: {
      type: Boolean,
      default: true,
    },
    appCode: {
      type: String,
      default: 'apple',
    },
    buttonStyle: {
      type: Object,
      default: () => ({}),
    },
    buttonBeforeStyle: {
      type: Object,
      default: () => ({}),
    },
    buttonAfterStyle: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      ANDROID_WARN,
      android_download_url: '',
    }
  },
  mounted() {
    if (OPEN_B) {
      this.getDownloadUrl()
    }
  },
  methods: {
    async getDownloadUrl() {
      uploadDownloadEvent(this.appCode, CLICK_DOWNLOAD, ANDROID)
      const query = getQueryValues()
      if (query.invite_code) {
        let s = JSON.stringify(query)
        let cb = () => {
          uploadDownloadEvent(this.appCode, COPY_SUCCESS, ANDROID)
        }
        clickCopy(s, '', true, cb)
      }
      const { data } = await getAndroidDownloadUrl(this.appCode)
      this.android_download_url = data.android_url
      this.download()
    },
    clickHelpDoc() {
      uploadDownloadEvent(this.appCode, CLICK_SOLUTION, ANDROID)
    },
    download() {
      triggerDownloadApk(this.android_download_url)
      uploadDownloadEvent(this.appCode, GET_DOWNLOAD_URL, ANDROID)
    },
  },
}
</script>

<style scoped>
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.tip_text {
  margin-top: 10px;
  border-radius: 4px;
  padding: 5px 10px;
  background: rgba(255, 255, 255, 0.5);
  font-size: 15px;
  text-align: center;
  font-weight: 600;
}
.solution {
  color: #666666;
}
</style>
